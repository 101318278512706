<template>
  <div 
    class="player"
    :class="{ active: playerDropdown === index}"
  >
    <div 
      class="player-head"
      @click="playerClick(index)"
    >
      <div class="player-label">
        <span>{{ player.name }}</span>
        <span class="name">{{ player.data.name }}</span>
      </div>
      <i class="fa-solid fa-chevron-down" :class="{ active: playerDropdown === index }"></i>
    </div>
    <div v-show="playerDropdown === index" class="player-dropdown">
      <div class="player-dropdown-item">
        <span>Fullname</span>
        <span class="value">{{player.data.name}}</span>
      </div>
      <div class="player-dropdown-item">
        <span>Origin</span>
        <span class="value">
          <img
            :src="`/icons/flags/${player.data.origin}.svg`"
            :alt="$getCountryName(player.data.origin)"
            :title="$getCountryName(player.data.origin)"
          />
          <span>{{ $getCountryName(player.data.origin) }}</span>
        </span>
      </div>
      <div class="player-dropdown-item">
        <span>Device</span>
        <span class="value" v-if="player.data.device !== null">
          <i v-if="player.data.device.os === 'ios'" class="fa-brands fa-apple"></i>
          <i v-if="player.data.device.os === 'android'" class="fa-brands fa-android"></i>
          <i v-if="player.data.device.type === 'phone'" class="fa-solid fa-mobile"></i>
          <i v-if="player.data.device.type === 'tablet'" class="fa-solid fa-tablet"></i>
          <span>{{ player.data.device.name }}</span>
        </span>
        <span class="value" v-else>
          <span>Unknown</span>
        </span>
      </div>
      <div class="player-dropdown-button">
        <router-link
          :to="`/${game}/player/${player._id}`"
        >
          View Player
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    playerDropdown: {
      required: true,
    }
  },
  computed: {
    game() {
      return this.$store.state.game;
    }
  },
  methods: {
    playerClick(index) {
      this.$emit("click", index);
    },
  }
}
</script>

<style>

</style>