<template>
  <main class="team">
    <PageHeader :title="`Team Profile - ${gameName}`" />
    <transition name="fade" mode="out-in">
      <main v-if="team" key="content">
        <h1>
          <div class="title-data">
            <img
              class="logo"
              :src="$cdn('team-logo', team)"
              :alt="team.name + ' logo'"
            />
            <div class="data">
              <span class="name">{{ team.name }}</span>
              <div class="origin">
                <img
                  :src="`/icons/flags/${team.data.origin}.svg`"
                  :alt="$getCountryName(team.data.origin)"
                  :title="$getCountryName(team.data.origin)"
                />
                <span>{{ $getCountryName(team.data.origin) }}</span>
              </div>
            </div>
          </div>
          <div class="title-socials">
            <div class="title-socials">
              <a v-for="soc in team.socials" :key="soc.type" target="__blank" :href="soc.link">
                <i :class="`fa-brands fa-${soc.type}`"></i>
              </a>
            </div>
          </div>
          <!--<div class="title-other">
          <img
            :src="`/icons/flags/${team.origin.code}.svg`"
            :alt="team.origin.name"
            :title="team.origin.name"
          />
          <span>{{ team.origin.name }}</span>
        </div>-->
        </h1>
        <Tabs :tabs="tabs" v-model="tab" fit @change="tabClick"></Tabs>
        <TabItems v-model="tab" :tabs="tabs">
          <template slot="info">
            <div class="panel">
              <div class="info-item">
                <span class="info-item-label">Origin</span>
                <span class="info-item-value">
                  <img
                    :src="`/icons/flags/${team.data.origin}.svg`"
                    :alt="$getCountryName(team.data.origin)"
                    :title="$getCountryName(team.data.origin)"
                  />
                  <span>{{ $getCountryName(team.data.origin) }}</span>
                </span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Region</span>
                <span class="info-item-value">
                  <!--<img
                    :src="`/icons/regions/${team.data.region}.svg`"
                    :alt="team.data.region"
                    :title="team.data.region"
                  />-->
                  {{ team.data.region.toUpperCase() }}
                </span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Founded</span>
                <span class="info-item-value">{{ teamFounded }}</span>
              </div>
            </div>
            <h2>Team Rankings</h2>
            <div class="panel ranking">
              <div class="info-item">
                <span class="info-item-label">Global rank</span>
                <span class="info-item-value">
                  #{{ team.ranking.global.rank }}
                </span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Global score</span>
                <span class="info-item-value">
                  {{ team.ranking.global.score }}
                </span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Region rank</span>
                <span class="info-item-value">
                  #{{ team.ranking.region.rank }}
                </span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Region score</span>
                <span class="info-item-value">
                  {{ team.ranking.region.score }}
                </span>
              </div>
            </div>
          </template>
          <template slot="players">
            <h2>Captain</h2>
            <div class="panel players">
              <TeamPlayer
                :player="captain"
                :index="0"
                :playerDropdown="playerDropdown"
                @click="playerClick"
              ></TeamPlayer>
            </div>
            <h2>Players</h2>
            <div class="panel players">
              <template v-if="players.length > 0">
                <TeamPlayer
                  v-for="(player, i) in players"
                  :key="player._id"
                  :player="player"
                  :index="i + 1"
                  :playerDropdown="playerDropdown"
                  @click="playerClick"
                ></TeamPlayer>
              </template>
              <span v-else class="no-players">
                No players.
              </span>
            </div>
          </template>
          <template slot="matches">
            <cops-match-preview
              v-for="match in team.matches"
              :key="match._id"
              :match="match"
            ></cops-match-preview>
          </template>
        </TabItems>
      </main>
      <main v-else-if="!team && !noTeam" key="loading">
        <div class="spinner">
          <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
        </div>
      </main>
      <main v-else key="no-team">
        <div class="spinner">
          <h2>Team not found.</h2>
          <button @click="$back">
            <i class="fa-solid fa-chevron-left fa-lg"></i>
            <span>Back</span>
          </button>
        </div>
      </main>
    </transition>
  </main>
</template>

<script>
import axios from "axios";
import moment from "moment";

import TeamPlayer from "../components/TeamPlayer.vue";
import CopsMatchPreview from '../components/c-ops/copsMatchPreview.vue';

export default {
  components: {
    TeamPlayer,
    CopsMatchPreview
  },
  data: () => ({
    team: null,
    noTeam: false,
    tab: "info",
    playerDropdown: null,
    tabs: [
      {
        name: "info",
        label: "Info",
      },
      {
        name: "players",
        label: "Players",
      },
      {
        name: "matches",
        label: "Matches",
      },
      {
        name: "events",
        label: "Events",
      },
    ],
  }),
  computed: {
    game() {
      return this.$store.state.game;
    },
    gameName() {
      return this.$games.find((g) => g.slug === this.game).name;
    },
    captain() {
      return this.team.players.filter((p) => p.role === "captain")[0];
    },
    players() {
      return this.team.players
        .filter((p) => p.leave === null && p.role !== "captain")
    },
    teamFounded() {
      return moment(this.team.data.founded).format("MMMM YYYY");
    },
  },
  created() {
    axios
      .get(this.$api("/teams/" + this.$route.params.team))
      .then((res) => {
        console.log(res.data);
        this.team = res.data;
        //this.injectPlayers();
      })
      .catch((err) => {
        console.log(err);
        this.noTeam = true;
      });
  },
  methods: {
    tabClick(tab) {
      //console.log("tab changed");
      this.$router.push({ query: { tab } });
    },
    roleIcon(role) {
      switch (role) {
        case "captain":
          return "fa-solid fa-user-tie";
        case "player":
          return "fa-solid fa-user";
        default:
          return "fa-solid fa-user";
      }
    },
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    playerClick(index) {
      this.playerDropdown = this.playerDropdown === index ? null : index;
    },
    injectPlayers() {
      this.team.players = [
        {
          id: 1,
          role: "player",
          data: {
            name: "Prokop Buben",
            age: "21",
            origin: {
              name: "Czech Republic",
              code: "cz",
            },
            device: {
              os: "ios",
              type: "tablet",
              name: "iPad Pro",
            }
          },
          name: "BubenKop",
          join: "2021-10-25T12:00:00.000Z",
          leave: null,
        },
        {
          id: 2,
          role: "player",
          data: {
            name: "Lukaš Šustr",
            age: "21",
            origin: {
              name: "Czech Republic",
              code: "cz",
            },
            device: {
              os: "ios",
              type: "tablet",
              name: "iPad 2020",
            }
          },
          name: "ŠukLust",
          join: "2021-10-25T12:00:00.000Z",
          leave: null,
        },
        {
          id: 3,
          role: "player",
          data: {
            name: "Tomáš Kříž",
            age: "21",
            origin: {
              name: "Czech Republic",
              code: "cz",
            },
            device: {
              os: "android",
              type: "phone",
              name: "Samsung Galaxy S10",
            }
          },
          name: "KrizTom",
          join: "2021-10-25T12:00:00.000Z",
          leave: null,
        },
        {
          id: "61d1de839ea6c05babbfc78f",
          role: "captain",
          data: {
            name: "Michal Winter",
            age: "18",
            origin: {
              name: "Czech Republic",
              code: "cz",
            },
            device: {
              os: "android",
              type: "phone",
              name: "Samsung Galaxy S10e",
            },
          },
          name: "Mikael Winter",
          join: "2021-10-25T12:00:00.000Z",
          leave: null,
        }
      ]
    },
  },
  watch: {
    $route: {
      handler(route) {
        if (!route.query.tab) {
          this.tab = "info";
        } else {
          this.tab = route.query.tab;
        }
      },
      immediate: true,
    },
  }
};
</script>

<style>
</style>